import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import api from '@/lib/api'
import { getAccessToken, getAnyAccessToken } from '@/lib/getAccessToken'
import {
  TruckVisit,
  TruckVisitDetail,
  TruckVisitDetailResponse,
  VisitHistoryResponse,
} from './types'
import { queryClient } from '@/pages/_app'

export const historyVisitQueryKey = ['visitHistory']
export const currentVisitQueryKey = ['currentVisit']

const orderDetailResponse = (data?: TruckVisitDetailResponse) =>
  data && {
    ...data.truckVisit,
    stages: data.truckVisit.stages.sort((a, b) => a.sortOrder - b.sortOrder),
    ...(data.truckVisit.routePlan
      ? {
          routePlan: {
            ...data.truckVisit.routePlan,
            containers: data.truckVisit.routePlan.containers.sort(
              (a, b) => a.sortOrder - b.sortOrder,
            ),
          },
        }
      : {}),
  }

export const useGetVisitHistory = (options?: UseQueryOptions) => {
  const accessToken = getAnyAccessToken()

  return useQuery<VisitHistoryResponse, unknown, TruckVisit[], any>({
    queryKey: historyVisitQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/history`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: VisitHistoryResponse) => data.truckVisits,
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && options.enabled
        : !!accessToken,
  })
}

export const useGetVisit = (
  options?: UseQueryOptions,
  truckVisitId?: string,
) => {
  const accessToken = getAnyAccessToken()

  return useQuery<TruckVisitDetailResponse, unknown, TruckVisitDetail, any>({
    queryKey: ['visit', truckVisitId],
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/${truckVisitId}`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: TruckVisitDetailResponse) => orderDetailResponse(data),
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && !!truckVisitId && options.enabled
        : !!accessToken && !!truckVisitId,
  })
}

export const useGetCurrentVisit = (
  options?: UseQueryOptions,
  _truckVisitId?: string,
) => {
  const accessToken = getAnyAccessToken()

  return useQuery<
    TruckVisitDetailResponse,
    unknown,
    TruckVisitDetail | false,
    any
  >({
    queryKey: currentVisitQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/active`,
        {
          method: 'GET',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : {}),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        accessToken ? accessToken.type : undefined,
      ),
    select: (data: TruckVisitDetailResponse) => orderDetailResponse(data),
    ...options,
    onError: (err: any) => {
      if (err?.code === 404) {
        queryClient.setQueryData(currentVisitQueryKey, () => {
          return false
        })
      }

      return options?.onError && options.onError(err)
    },
    enabled:
      options && typeof options.enabled === 'boolean'
        ? !!accessToken && options.enabled
        : !!accessToken,
  })
}

export const useMutateCancelVisit = (
  options?: UseMutationOptions<
    any,
    unknown,
    {
      truckVisitId?: string | string[]
      leaveTerminalImmediately: boolean
      containerNumbers?: string[]
    },
    unknown
  >,
) => {
  const accessToken = getAnyAccessToken()

  return useMutation({
    mutationFn: ({
      leaveTerminalImmediately,
      truckVisitId,
      containerNumbers = null,
    }) =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/MyTerminalTruckVisitStatus/api/truckvisit/v1/${truckVisitId}/cancel`,
        {
          method: 'POST',
          headers: {
            ...(accessToken
              ? { Authorization: `Bearer ${accessToken.token}` }
              : { Authorization: `Bearer ${getAccessToken()}` }),
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
            'Content-Type': 'application/json, */*',
          },
          body: JSON.stringify({
            leaveTerminalImmediately,
            containerNumbers,
          }),
        },
        'STS',
      ),
    ...options,
  })
}
